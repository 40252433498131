import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import Helmet from 'react-helmet';

import { Button as MuiButton, CircularProgress } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Paper, Typography } from "../dashboards/Util/SpacedComponents";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { popAlert } from "../../redux/actions/sessionActions";
import { verifyEmailAddress } from "../../redux/util/companyAPI_ts";
import { parseError } from "../../redux/reducers/helpersReducerr";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

function CompanyEmailVerification() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()

    const [successful, setSuccessfull] = useState(false)

    const verifyCompanyEmail = async () => {
        const token = new URLSearchParams(location.search).get("token")
        if (!token) {
            dispatch(popAlert('error', t('error'), "Unable to get token"))
            return;
        }

        try {
            const response = await verifyEmailAddress({ token })
            if (!response.success) {
                dispatch(popAlert('error', t('error'), parseError(response)))
                return;
            }
            history.push("/portal/companies/list")
        } catch (e) {
            dispatch(popAlert('error', t('error'), e))
        }
    }

    useEffect(() => {
        verifyCompanyEmail()
    }, [])


    return (
        <Wrapper>
        <Helmet title="Email Verification" />
        <Paper p={5}>
            <Typography variant="h5" align="center" mb={5}>
            Confirming company email...
            </Typography>

            <CircularProgress />
        </Paper>
        </Wrapper>
    );
    }

export default CompanyEmailVerification;
