import { 
    Grid as MuiGrid, 
    Paper as MuiPaper, 
    Typography as MuiTypography, 
    Divider as MuiDivider,
    FormControl as MuiFormControl,
    DialogContent as MuiDialogContent,
    Button as MuiButton,
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

export const Paper = styled(MuiPaper)(spacing);
export const SpacedTypography = styled(MuiTypography)(spacing);
export const Typography = styled(MuiTypography)(spacing);
export const SpacedGrid = styled(MuiGrid)(spacing);
export const Grid = styled(MuiGrid)(spacing);

export const Divider = styled(MuiDivider)(spacing);
export const FormControl = styled(MuiFormControl)(spacing);
export const SpacedFormControl = styled(MuiFormControl)(spacing);
export const SpacedPaper = styled(MuiPaper)(spacing);
export const DialogContent = styled(MuiDialogContent)(spacing);
export const Button = styled(MuiButton)(spacing);
