import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation, useParams } from "react-router-dom";

import Helmet from 'react-helmet';

import { Button as MuiButton, CircularProgress } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Paper, Typography } from "../dashboards/Util/SpacedComponents";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { popAlert } from "../../redux/actions/sessionActions";
import { resendCompanyEmailVerificationEmail, verifyEmailAddress } from "../../redux/util/companyAPI_ts";
import { parseError } from "../../redux/reducers/helpersReducerr";
import { useRootSelector } from "../..";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

function CompanyEmailNotVerified() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const token = useRootSelector(state => state.session.user.token!!)
    const companyId = useRootSelector(state => state.session.company.id!!)

    const resendEmail = async () => {
        try {
            const response = await resendCompanyEmailVerificationEmail({ token, companyId })
            if (!response.success) {
                dispatch(popAlert('error', t('error'), parseError(response)))
                return;
            }
            dispatch(popAlert('success', t('success'), "Email resent."))
        } catch (e) {
            dispatch(popAlert('error', t('error'), e))
        }
    }


    return (
        <Wrapper>
        <Helmet title="Email Verification" />
        <Paper p={5}>
            <Typography variant="h5" align="center" mb={5}>
                Please check the company email for a verification email.
            </Typography>

            <Button
                onClick={resendEmail}
                fullWidth
            >
                Resend Email
            </Button>
            <Button
                onClick={() => window.location.reload()}
                fullWidth
            >
                Email Confirmed
            </Button>

            {/* <CircularProgress /> */}
        </Paper>
        </Wrapper>
    );
    }

export default CompanyEmailNotVerified;
