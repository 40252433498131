import { Address } from "./Location";
import { Employee } from "./User";

export interface Company {
    id: number;
    address_id: number;
    address: Address;
    created_at: string;
    default_job_terms_and_conditions: string | null;
    description: string;
    email_address: string;
    employees?: Employee[];
    entity_type_id: number;
    entity_type: CompanyEntityType;
    federal_tax_classification: string;
    has_tin: boolean;
    isAdmin: boolean;
    isForeign: boolean;
    is_email_verified: boolean;
    is_minority_company: boolean;
    legal_docs: LegalDoc[];
    letter_head_image: string;
    letter_head_text: string;
    minority_certification_id: string;
    minority_classification: MinorityClassification;
    minority_classification_id: number;
    name: string;
    perms: CompanyPermission[];
    phone_country_code: string;
    phone_number: string;
    formattedPhoneNumber?: string;
    position_id: number;
    roles: CompanyRole[],
    stripe_customer_id: string;
    stripe_merchant_id: string;
    title: string;
    trials: [];
    usdot: number;
}
export interface CompanyPermission {
    description: string;
    display_name: string;
    name: string;
}
export interface CompanyEntityType {
    id: number;
    name: string;
    display_name: string;
    description: string;
}


export enum CompanyEntityTypeNames {
    Subcontractor = "subcontractor",
    Construction = "construction-company",
    Shipping = "shipping-company",
    IOO = "independent-owner-operator",
    MaterialSupplier = "material-supplier",
    DemolitionContractor = "demolition-contractor",
    WasteHauler = "waste-hauler",
    DEP = "dep",
    DOT = "dot",
}

export const DriverEntityTypeNames = [
    CompanyEntityTypeNames.Subcontractor,
    CompanyEntityTypeNames.Shipping,
    CompanyEntityTypeNames.IOO,
    CompanyEntityTypeNames.WasteHauler,
]


export interface LegalDoc {
    id: number;
    company_id: number;
    document_photo_url: string;
    expiration_date: string;
    file_type: string;
    legal_document_type: number;
    valid_general_liability_insurance: number;
    valid_vehicle_insurance: number;
    verified: number;
}
export interface MinorityClassification {
    id: number;
    name: string;
    description: string;
}
export interface Permission {
    id: number;
    description: string;
    display_name: string;
    name: string;
    admin: boolean;
}
export interface Role {
    id: number;
    name: string;
    display_name: string;
    description: string;
    admin: false,
    perms: Permission[]
}
export interface CompanyRole {
    name: string;
    display_name: string;
    description: string;
}

export interface CompanyPositionApi {
    id: number;
    name: string;
    title: string;
    description: string;
    created_at: string;
    updated_at: string;
}

export enum Permissions {
    CANCEL_DELIVERY = "cancel-delivery",
}