import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from "react-router-dom";
import { 
  dashboardLayoutRoutes, 
  authLayoutRoutes, 
  companiesListLayoutRoutes, 
  companyDriverRoutes, 
  driverRoutes, 
  subConRoutes, 
  companyRoutes, 
  adminRoutes,
  plantOrdersRoutes,
  inspectorDeliveriesRoutes,
  inspectorLogbookRoutes,
  noAuthInspectorDeliveriesRoutes,
  wasteHaulerRoutes,
  inspectorLayoutRoutes,
  inspectorSupervisorRoutes,
  inspectorSupervisorRoute,
  governmentEntityRoutes,
} from "./index";
import { connect } from 'react-redux';
import { 
  getNotificationsCount, 
  popAlert, 
  refreshCompany,
  getDutyStatus,
} from '../redux/actions/sessionActions';
import { getRestrictionDate } from '../redux/util/companyAPI';
import { getStyleSettings, setStyleSettings } from '../redux/util/userAPI';
import { setStyleOptions } from '../redux/actions/themeActions';
import { PUSHER_KEY } from '../env';
import { functions } from "../redux/reducers/helpersReducer";
import InspectorDeliveries from "../pages/dashboards/Inspector/InspectorDeliveries";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import SecurityAuth from '../pages/auth/SecurityAuth';
import { Typography } from "@material-ui/core";
import { triggerInspectorFeedUpdate } from "../redux/actions/inspectorActions";
import { can } from "../redux/reducers/helpersReducerr";
import MTSTermsOfService from "../pages/docs/MTSTermsOfService";
import MTSPrivacyPolicy from "../pages/docs/MTSPrivacyPolicy";
import TPTermsOfService from "../pages/docs/TPTermsOfService";
import TPPrivacyPolicy from "../pages/docs/TPPrivacyPolicy";
import { CompanyEntityTypeNames } from "../redux/reducers/entities";
import CompanyEmailVerification from "../pages/auth/EmailVerification";
import CompanyEmailNotVerified from "../pages/auth/CompanyEmailNotVerified";

const childRoutes = (Layout, routes, additionalProps = {}) => {
  return routes.map(({ children, path, component: Component, hidden }, index) => {
    if (children) {
      return children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          render={props => (
            <Layout 
              routes={routes} 
              companyRestrictionDate={additionalProps.companyRestrictionDate}
              updateStyleSettings={additionalProps.updateStyleSettings}
            >
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    } else {
      return (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout 
              routes={routes} 
              companyRestrictionDate={additionalProps.companyRestrictionDate}
              updateStyleSettings={additionalProps.updateStyleSettings}
            >
              <Component {...props} />
            </Layout>
          )}
        />
      )
    }
  }
    // children ? (
    //   // Route item with children
    //   children.map(({ path, component: Component }, index) => (
    //     <Route
    //       key={index}
    //       path={path}
    //       render={props => (
    //         <Layout routes={routes}>
    //           <Component {...props} />
    //         </Layout>
    //       )}
    //     />
    //   ))
    // ) : (
    //   // Route item without children
    //   <Route
    //     key={index}
    //     path={path}
    //     exact
    //     render={props => (
    //       <Layout routes={routes}>
    //         <Component {...props} />
    //       </Layout>
    //     )}
    //   />
    // )
  );
}

const msp = state => ({
  currentUser: state.session.user,
  company: state.session.company,
  loggedIn: Boolean(state.session.user && state.session.user.token)
});

const Auth = ({ component: Component, path, currentUser, loggedIn, loading }) => {
  // loading();
  return (<Route
    path={path}
    render={props => (
      loggedIn ? <Redirect to="/portal/companies/list" /> : <Component {...props} />
    )}
  />)
};

const Protected = ({ component: Component, path, currentUser, loggedIn, loading, companyRestrictionDate, updateStyleSettings, company }) => {
  // loading();
  return (<Route
    path={path}
    render={props => (
      loggedIn ? <Component {...props} companyRestrictionDate={companyRestrictionDate} updateStyleSettings={updateStyleSettings} company={company}/> : <Redirect to="/auth/sign-in" />
    )}
  />)
};

const AuthRoute = withRouter(connect(msp, null)(Auth));
const ProtectedRoute = withRouter(connect(msp, null)(Protected));

const AuthRoutesComponent = function() {
  return (
    <>
      {childRoutes(AuthLayout, authLayoutRoutes)}
    </>
  )
}

/**
 * Controls which routes (menu items) get shown based on either title, company type, or company permissions
 */
const ProtectedRoutesComponent = function ({ companyRestrictionDate, company, updateStyleSettings }) {
  const [finalRoutes, setFinalRoutes] = useState([])
  useEffect(() => {
    let routes = []
    if (company.isAdmin) {
      routes = [...adminRoutes]
    } else if (company.title == 'Truck Driver') {
      routes = [...companyDriverRoutes]
    } else if (company.title === 'Delivery Inspector') {
      routes = [...inspectorLayoutRoutes]
    } else if (company.entity_type.name == 'independent-owner-operator') {
      routes = [...driverRoutes]
    } else if (company.entity_type.name == 'subcontractor') {
      routes = [...subConRoutes]
    } else if (
      company.entity_type.name == 'waste-hauler' 
      || company.entity_type.name === CompanyEntityTypeNames.Shipping
    ) {
      routes = [...wasteHaulerRoutes]
    } else {
      routes = [...companyRoutes]
    }
    if (functions.can(company.perms, 'view-plant-report')) {
      routes.unshift(plantOrdersRoutes)
    }
    if (can(company.perms, 'view-company-inspections')) {
      routes.unshift(inspectorSupervisorRoute)
    }

    // Government routes
    if (['dep', 'dot'].includes(company.entity_type.name)) {
      if (company.title === 'Delivery Inspector') {
        routes = [...inspectorLayoutRoutes]
      } else {
        routes = [...governmentEntityRoutes]
      }
    }
    setFinalRoutes(routes)

  }, [company])
  // finalRoutes = dashboardLayoutRoutes;
  return (
    <>
      {childRoutes(DashboardLayout, finalRoutes, { companyRestrictionDate, updateStyleSettings })}
    </>
  )
}
const CompaniesListComponent = function ({ updateStyleSettings }) {
  return (
    <>
      {childRoutes(DashboardLayout, companiesListLayoutRoutes, { updateStyleSettings })}
    </>
  )
}
const NoAuthInspectorComponent = function ({ updateStyleSettings }) {
  return (
    <>
      {childRoutes(DashboardLayout, [noAuthInspectorDeliveriesRoutes, inspectorLogbookRoutes], { updateStyleSettings })}
    </>
  )
}



class Routes extends React.Component{
  mounted = false
  state = {
    subscribedToPusherCompanyID: "",
    companyRestrictionDate: "",
    dutyStatus: false,
    isAuthRequired: false,
    routes: [],
    paths: [],
  }
  componentDidMount = () => {
    this.mounted = true;
    if (this.props.user && this.props.user.token) {
      console.log("mounting with user... getting styles")
      this.getStyleSettings()
    }

    if (this.props.company && this.props.company.id && this.props.company.id != this.state.subscribedToPusherCompanyID) {
      this.setStateIfMounted({subscribedToPusherCompanyID: this.props.company.id})
      console.log("company loaded, refreshing", this.props.company.id)
      this.props.refreshCompany(this.props.token, this.props.company.id)
      this.subToPusher()
      this.getCompanyRestrictionDate()
      this.getDutyStatus();
      this.props.getNotificationsCount(this.props.token, this.props.company.id)
    } else {
      this.unsubFromPusher()
    }

    const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
    if (env == 'dev') {
      this.setStateIfMounted({ isAuthRequired: true })
    }
  }
  updateStyleSettings = newSettings => {
    setStyleSettings(this.props.token, newSettings).then(res => {
      console.log("set style", res)
      if (res.success) {
        this.props.setStyleOptions({
          textSize: res.data.text_size,
          theme: res.data.theme,
        })
      }
    })
  }
  getStyleSettings = () => {
    getStyleSettings(this.props.token).then(res => {
      console.log("get style", res)
      if (res && res.success) {
        this.props.setStyleOptions({
          textSize: res.data.text_size,
          theme: res.data.theme,
        })
      }
    })
  }
  componentWillUnmount = () => {
    this.mounted = false
  }
  setStateIfMounted = (state, callback) => {
    if (this.mounted) {
      this.setState(state, callback)
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.user != this.props.user && ((prevProps.user && prevProps.user.token && prevProps.user.token != this.props.user.token) || (!prevProps.user && this.props.user && this.props.user.token))) {
      console.log("updated user... getting styles", prevProps.user, this.props.user)
      this.getStyleSettings()
    }

    // Changes in the company
    if (prevProps.company != this.props.company && this.props.company?.id) {
      // Company is selected or changed

      // Calculate new routes
      this.calculateRoutes()

      // Company changed so update Pusher channels
      if (this.props.company.id != this.state.subscribedToPusherCompanyID && window.Pusher) {
        console.log("company updated, sub to pusher", this.props.company.id)
        this.setStateIfMounted({subscribedToPusherCompanyID: this.props.company.id})
        this.subToPusher()
        this.getCompanyRestrictionDate()
        this.getDutyStatus();
        this.props.getNotificationsCount(this.props.token, this.props.company.id)
      }

    } else {
      // User logs out
      this.unsubFromPusher()
    }
  }
  getCompanyRestrictionDate = () => {
    getRestrictionDate(this.props.token, this.props.company.id).then(res => {
      if (res && res.data && !res.data.is_whitelisted) {
        console.log("restricted date", this.props.helpers.getFormattedLongDate(new Date(res.data)))
        const dateParts = res.data.split("-")
        const restricedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
        this.setStateIfMounted({
          companyRestrictionDate: restricedDate
        })
      } else {
        this.setStateIfMounted({
          companyRestrictionDate: ""
        })
      }
    })
  }
  subToPusher = () => {
    if (window.Pusher && this.props.company && this.props.company.id) {
      try {
        console.log("binding pusher " + this.props.company.id)
        if (!this.pusher) {
          this.pusher = new window.Pusher(PUSHER_KEY);
        }
        if (this.publicChannel) {
          this.publicChannel.unbind()
        }
        this.publicChannel = this.pusher.subscribe(`company-${this.props.company.id}`)
        this.publicChannel.bind('notification', (data) => {
          console.log("public channel notif", data)

          if (
            data.notification_type === "driver-checked-in" ||
            data.notification_type === "load-ready-for-inspection"

          ) {
            this.props.triggerInspectorFeedUpdate(true)
          }

          this.showNotification(data)
        })
        if (this.props.user && this.props.user.id) {
          if (this.privateChannel) {
            this.privateChannel.unbind()
          }
          this.privateChannel = this.pusher.subscribe(`company-${this.props.company.id}-${this.props.user.id}`)
          this.privateChannel.bind('notification', (data) => {
            console.log("private channel notif", data)

            if (
              data.notification_type === "driver-checked-in" ||
              data.notification_type === "load-ready-for-inspection"
            ) {
              this.props.triggerInspectorFeedUpdate(true)
            }

            this.showNotification(data)
          })
        }

        console.log('pusher started', this.pusher, this.publicChannel, this.privateChannel)
      } catch (e) {
        console.log('pusher err', e)
      }
    }
  }
  unsubFromPusher = () => {
    // when the company is cleared as {}
    if (window.Pusher && this.props.company && !this.props.company.id) {
      if (this.privateChannel) {
        this.privateChannel.unbind()
      }
      if (this.publicChannel) {
        this.publicChannel.unbind()
      }
    }
  }
  getDutyStatus = () => {
    if (this.props.company.title == 'Truck Driver' || this.props.company.entity_type.name == 'independent-owner-operator') {
      this.props.getDutyStatus(this.props.token)
    }
  }
  showNotification = (data) => {
    this.props.popAlert('info', 'Notification', data.notification_text)
  }
  passedSecurityValidation = bool => {
    if (bool) {
      this.setStateIfMounted({ isAuthRequired: false })
    }
  }
  calculateRoutes = () => {
    const { company } = this.props
    let routes = []

    if (!company?.id) return routes

    if (company.isAdmin) {
      routes = [...adminRoutes]
    } else if (company.title == 'Truck Driver') {
      routes = [...companyDriverRoutes]
    } else if (company.title === 'Delivery Inspector') {
      routes = [...inspectorLayoutRoutes]
    } else if (company.entity_type.name == 'independent-owner-operator') {
      routes = [...driverRoutes]
    } else if (company.entity_type.name == 'subcontractor') {
      routes = [...subConRoutes]
    } else if (company.entity_type.name == 'waste-hauler') {
      routes = [...wasteHaulerRoutes]
    } else {
      routes = [...companyRoutes]
    }
    if (functions.can(company.perms, 'view-plant-report')) {
      routes.unshift(plantOrdersRoutes)
    }
    if (can(company.perms, 'view-company-inspections')) {
      routes.unshift(inspectorSupervisorRoute)
    }

    // Government routes
    if (['dep', 'dot'].includes(company.entity_type.name)) {
      if (company.title === 'Delivery Inspector') {
        routes = [...inspectorLayoutRoutes]
      } else {
        routes = [...governmentEntityRoutes]
      }
    }

    /*
      routes: {
        id: string,
        path: string,
        icon: React.Element,
        containsHome: boolean,
        children?: {
          path: string,
          name: string,
          component: React.Element
        }[],
        component?: React.Element
      }[]
    */
    const paths = []
    routes.forEach(route => {
      // If the route has children, grab each child's path
      if (route.children?.length) {
        paths.push(...route.children.map(childRoute => childRoute.path))
      }

      // Route doesn't have children so take top level path
      paths.push(route.path)
    })

    this.setStateIfMounted({
      routes,
      paths,
    })
  }
  render = () => {
    if (this.state.isAuthRequired) {
      return (
        <AuthLayout>
          <SecurityAuth passedSecurityValidation={this.passedSecurityValidation}/>
        </AuthLayout>
      )
    } else {
      return (
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect from="/" to="/auth/sign-in" />
            </Route>
            <Route exact path="/mytruckscales_terms_of_service">
              <AuthLayout>
                <MTSTermsOfService />
              </AuthLayout>
            </Route>
            <Route exact path="/mytruckscales_privacy_policy">
              <AuthLayout>
                <MTSPrivacyPolicy />
              </AuthLayout>
            </Route>
            <Route exact path="/truckpay_terms_of_service">
              <AuthLayout>
                <TPTermsOfService />
              </AuthLayout>
            </Route>
            <Route exact path="/truckpay_privacy_policy">
              <AuthLayout>
                <TPPrivacyPolicy />
              </AuthLayout>
            </Route>
            <Route exact path="/email-verification">
              <AuthLayout>
                <CompanyEmailVerification />
              </AuthLayout>
            </Route>
            <AuthRoute path={"/auth"} component={AuthRoutesComponent} />
            <AuthRoute path={[
              "/x-inspector-logbook",
              "/inspector/:inspectorToken/job/:jobID/deliveries",
            ]} component={NoAuthInspectorComponent} />
            <ProtectedRoute 
              path="/portal/companies/list" 
              component={CompaniesListComponent} 
              updateStyleSettings={this.updateStyleSettings}
            />
            {Boolean(this.props.company?.id) && <>
              {this.props.company.is_email_verified ? <>
                <ProtectedRoute
                  path={this.state.paths}
                  component={ProtectedRoutesComponent}
                  companyRestrictionDate={this.state.companyRestrictionDate}
                  updateStyleSettings={this.updateStyleSettings}
                />
              </> : <>
                <Route
                  render={() => (
                    <AuthLayout>
                      <CompanyEmailNotVerified />
                    </AuthLayout>
                  )}
                />
              </>}
            </>}
            <Route
              render={() => {
                if (!this.props.company || !this.props.user?.id) {
                  return <Redirect to={"/auth/sign-in"} />
                } 
                return <AuthLayout>
                  <Page404 />
                </AuthLayout>
              }}
            />
          </Switch>
        </Router>
      )
    }
  }
} 
const routesmsp = state => ({
  company: state.session.company,
  token: state.session.user.token,
  helpers: state.helpers,
  user: state.session.user
})
const routesmdp = dispatch => ({
  popAlert: (type, title, message) => dispatch(popAlert(type, title, message)),
  getNotificationsCount: (token, companyID) => dispatch(getNotificationsCount(token, companyID)),
  refreshCompany: (token, companyID) => dispatch(refreshCompany(token, companyID)),
  getDutyStatus: token => dispatch(getDutyStatus(token)),
  setStyleOptions: options => dispatch(setStyleOptions(options)),
  triggerInspectorFeedUpdate: (shouldUpdate) => dispatch(triggerInspectorFeedUpdate(shouldUpdate))
})
export default connect(routesmsp, routesmdp)(Routes)
